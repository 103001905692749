import { useTranslation } from 'react-i18next';

const CommonSectionArea = ({ area_header }) => {
   const { t } = useTranslation();

   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>{t("about_us_key")}</h5>
                           <h1>{t("short_story_clinic_key")}</h1>
                        </div>
                        <div className="about-text mb-50">
                           <p>«Դավթաշեն» առողջության կենտրոն ՓԲԸ-ի  կողմից բնակչությանը տրամադրվում
                              է պետության կողմից երաշխավորված անվճար արտահիվանդանոցային բժշկական
                              օգնություն և սպասարկում:</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt="" />
                              </div>
                              <div className="mv-title fix">
                                 <h3>{t("our_mission_key")}</h3>
                                 <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt="" />
                              </div>
                              <div className="mv-title fix">
                                 <h3>{t("our_vision_key")}</h3>
                                 <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;